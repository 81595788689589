<template>

<!-- MAIN (Center website) -->
<div class="main">

<!--  <lingallery :showThumbnails="showThumbnails" :addons="{ enablePictureElement: true }" :width="800" :height="600" :items="items"/>-->
<!--  <lingallery :responsive="false" :addons="{ enableLargeView: true }" :showThumbnails="showThumbnails" :addons="{ enablePictureElement: true }" :width="800" :height="600" :items="items"/> -->
  <lingallery :responsive="true" :showThumbnails="showThumbnails" :width="800" :height="600" :items="items"/>

</div>
</template>

<script>
 import Lingallery from 'lingallery';

  export default {
    name: 'PhotoCal',
    props: ['tabphotos', 'showThumbnails'],

    components: { Lingallery },
    computed: {
      items() {
        var temp=[], i;
        for (i=0; i<this.tabphotos.length; i++) {
          temp[i] = { id: 'someid'+i,
                      src: this.tabphotos[i].hero,
                      thumbnail: this.tabphotos[i].hero,
                      caption: this.tabphotos[i].caption }
        }
        return temp;
      },

    },

  }
</script>

<style scoped>
 /* Center website */
.main {
  max-width: 2000px;
  margin: auto;
  margin-top: -10px;
}


</style>
